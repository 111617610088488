import React from "react"
import { Box } from "@material-ui/core"
import AuthLayout from "../components/Layout/AuthLayout"
import { Location } from "@reach/router"

export default props => {
  return (
    <Location>
      {locationProps => (
        <>
          {/* <Layout> */}
          <Box>
            <AuthLayout {...locationProps} {...props} />
          </Box>
          {/* </Layout> */}
        </>
      )}
    </Location>
  )
}
